html, body {
  font-family: 'Barlow', sans-serif;
  overflow-x: hidden; }

#root {
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  overflow-x: hidden; }
  #root > div > div {
    width: 100%;
    float: left;
    overflow-x: hidden; }

button {
  cursor: pointer; }

@media only screen and (max-width: 768px) {
  .hide-mobile {
    display: none !important; } }

@media only screen and (min-width: 767px) {
  .hide-desktop {
    display: none !important; } }

.text-primary {
  color: #DA212E !important; }

.footer {
  position: relative;
  overflow: hidden;
  padding-bottom: 32px;
  width: 100%;
  float: left; }
  .footer h6 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    width: 100%; }
  .footer .nav {
    width: 100%; }
    .footer .nav ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      float: left; }
      .footer .nav ul > li {
        width: 50%;
        float: left; }
        .footer .nav ul > li a {
          color: #333333; }
  .footer .logo {
    width: 170px;
    margin-bottom: 48px; }
  .footer .description {
    margin-bottom: 40px;
    font-size: 16px;
    color: #333333; }
  .footer .social {
    color: #333333;
    font-size: 16px; }
    .footer .social .isvg {
      margin: 0 6px; }
  .footer .contact {
    font-size: 14px;
    color: #333333;
    margin-bottom: 48px; }
    .footer .contact i {
      color: #DA212E;
      font-size: 16px; }
    .footer .contact p {
      font-size: 20px;
      font-weight: 600;
      color: #333333; }
  .footer .spacer {
    width: 100%;
    float: left;
    height: 1px;
    background: #D1D1D1;
    position: relative;
    margin: 0 15px;
    margin-bottom: 35px; }
    .footer .spacer::after {
      content: " ";
      position: absolute;
      right: 0;
      top: -4px;
      width: 100px;
      height: 4px;
      background-image: url(./assets/images/title.png);
      display: block; }
  .footer .copyright {
    color: #333333;
    font-size: 16px;
    width: 100%;
    margin: 0 15px; }
    .footer .copyright a {
      color: #333333; }
    .footer .copyright > p {
      width: 50%;
      float: left; }
      .footer .copyright > p > span {
        font-weight: 600; }
      .footer .copyright > p:nth-child(2) {
        text-align: right; }
  .footer .info {
    text-align: right; }
    .footer .info h6 {
      color: #2F3234;
      font-size: 22px;
      font-weight: 600; }
    .footer .info p {
      color: #D1D1D1;
      font-size: 18px; }

.section {
  padding: 60px 0;
  float: left;
  width: 100%; }
  @media only screen and (max-width: 768px) {
    .section {
      padding: 30px 0; } }
  .section .sub-title {
    margin-bottom: 45px;
    padding-top: 32px; }
    .section .sub-title::after {
      content: ' ';
      background-image: url(./assets/images/title.png);
      display: block;
      float: left;
      height: 4px;
      width: 100px;
      position: absolute;
      top: 0px;
      left: 50%;
      margin-left: -50px; }
    .section .sub-title h2 {
      font-size: 46px;
      font-weight: 600; }
      @media only screen and (max-width: 768px) {
        .section .sub-title h2 {
          font-size: 28px; } }
    .section .sub-title p {
      font-size: 18px; }

.map-section {
  position: relative;
  padding: 0 0 40px 0; }
  .map-section .container-fluid {
    padding: 0 !important; }
  .map-section .map {
    width: 100%;
    height: 500px; }
    @media only screen and (max-width: 768px) {
      .map-section .map {
        height: 200px; } }

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  background-color: rgba(35, 38, 40, 0.9);
  z-index: 9999; }
  .lightbox .mdi-close {
    position: fixed;
    top: 16px;
    right: 16px;
    font-size: 48px;
    color: #FFFFFF;
    cursor: pointer;
    z-index: 99999; }
  .lightbox .lightbox-item {
    padding: 100px 200px;
    width: 100%;
    height: 100vh; }
    .lightbox .lightbox-item img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center; }
    .lightbox .lightbox-item .title {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
      padding-top: 16px; }
  .lightbox .carousel-control-prev-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }
  .lightbox .carousel-control-next-icon {
    width: 48px;
    height: 48px;
    cursor: pointer; }

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
  border-radius: rem-calc(5); }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #DA212E;
  border-radius: rem-calc(5);
  cursor: pointer; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DA212E;
  cursor: pointer; }

.button {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DA212E;
  color: #FFFFFF !important;
  float: left;
  border-radius: 27px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer; }

.mobile-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  height: 100vh;
  padding: 150px 30px;
  margin: 0;
  list-style: none;
  background-image: linear-gradient(to bottom right, #DA212E, #000);
  overflow: hidden; }
  .mobile-navigation > li {
    color: #FFFFFF;
    padding: 5px 0; }
    .mobile-navigation > li a {
      color: #FFFFFF;
      font-size: 32px; }
  .mobile-navigation::after {
    position: fixed;
    top: 0;
    left: 0;
    content: ' ';
    display: block;
    width: 20px;
    height: 76vh;
    transform: translate3d(70vw, 12vh, 0);
    margin-left: -15px;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    background-color: #FFFFFF;
    z-index: 2; }

.header {
  z-index: 999999;
  background-color: #FFFFFF;
  height: 100px; }
  @media only screen and (max-width: 767px) {
    .header {
      height: 70px; } }
  .header .logo {
    padding: 24px 0; }
    @media only screen and (max-width: 767px) {
      .header .logo {
        padding: 20px 0; } }
    .header .logo img {
      height: 100%; }
      @media only screen and (max-width: 767px) {
        .header .logo img {
          width: 100%;
          height: auto; } }
  .header .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: center; }
    .header .mobile-menu > i {
      font-size: 32px; }
  .header .right {
    z-index: 2;
    font-size: 14px; }
    .header .right .top {
      color: #888888;
      border-bottom: 1px solid #888888;
      padding: 16px 0; }
      .header .right .top > div {
        padding-left: 0 !important;
        padding-right: 0 !important; }
        .header .right .top > div:nth-child(2) {
          text-align: right; }
      .header .right .top .dropdown {
        text-transform: uppercase;
        cursor: pointer; }
        .header .right .top .dropdown .dropdown-toggle::after {
          content: ' ';
          border-top: 0;
          border-right: 0;
          border-bottom: 2px solid #888888;
          border-left: 2px solid #888888;
          padding: 3px;
          transform: rotate(-45deg);
          margin-left: 8px; }
    .header .right .info {
      color: #333333;
      padding: 12px 0;
      margin-bottom: 12px;
      border-bottom: 1px solid #E1E1E1; }
      .header .right .info i {
        color: #DA212E;
        font-size: 16px;
        margin-right: 8px; }
      .header .right .info .isvg {
        padding: 0 8px; }
        .header .right .info .isvg svg {
          width: 16px;
          height: 16px; }
      .header .right .info > div {
        padding-left: 0 !important;
        padding-right: 0 !important; }
        .header .right .info > div:nth-child(3) {
          text-align: right; }
    .header .right .navigation {
      color: #333333; }
      .header .right .navigation > ul {
        padding-left: 0 !important;
        padding-right: 0 !important;
        justify-content: space-between;
        align-items: center;
        width: 100%; }
        .header .right .navigation > ul a {
          color: #333333;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 18px;
          -webkit-transform: translateZ(0);
          transform: translateZ(0);
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          -moz-osx-font-smoothing: grayscale;
          position: relative;
          overflow: hidden;
          padding: 15px 0; }
          .header .right .navigation > ul a::before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 50%;
            right: 50%;
            bottom: 0;
            background: #DA212E;
            height: 4px;
            -webkit-transition-property: left right;
            transition-property: left right;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out; }
          .header .right .navigation > ul a:hover {
            color: #DA212E;
            text-decoration: none; }
            .header .right .navigation > ul a:hover::before {
              left: 0;
              right: 0; }
        .header .right .navigation > ul .active {
          color: #DA212E;
          text-decoration: none; }
          .header .right .navigation > ul .active::before {
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            bottom: 0;
            background: #DA212E;
            height: 4px; }
        .header .right .navigation > ul .dropdown {
          text-transform: uppercase;
          cursor: pointer; }
          .header .right .navigation > ul .dropdown .dropdown-toggle::after {
            content: ' ';
            border-top: 0;
            border-right: 0;
            border-bottom: 2px solid #FFFFFF;
            border-left: 2px solid #FFFFFF;
            padding: 3px;
            transform: rotate(-45deg);
            margin-left: 8px; }
          .header .right .navigation > ul .dropdown .dropdown-menu {
            margin-top: 10px;
            margin-left: 40px;
            border-radius: 10px;
            border: none;
            -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
            padding: 21px; }
            .header .right .navigation > ul .dropdown .dropdown-menu .dropdown-divider:last-child {
              display: none; }
            .header .right .navigation > ul .dropdown .dropdown-menu button {
              font-size: 16px;
              font-weight: 600;
              color: #2F3234;
              padding: 0;
              min-width: 170px; }
              .header .right .navigation > ul .dropdown .dropdown-menu button:hover {
                color: #DA212E; }
              .header .right .navigation > ul .dropdown .dropdown-menu button:active {
                background: transparent;
                outline: 0; }
            .header .right .navigation > ul .dropdown .dropdown-menu::before {
              content: ' ';
              display: block;
              background-color: #FFFFFF;
              width: 36px;
              height: 36px;
              border-radius: 6px;
              transform: rotate(45deg);
              position: absolute;
              top: -5px;
              left: 22px;
              z-index: -1; }
  .header .sub-header {
    float: left;
    position: relative;
    height: 180px; }
    @media only screen and (max-width: 768px) {
      .header .sub-header {
        height: 150px; } }
    .header .sub-header .info {
      margin-top: 100px; }
      @media only screen and (max-width: 768px) {
        .header .sub-header .info {
          margin-top: 80px; } }
      .header .sub-header .info > div {
        padding-left: 0 !important;
        padding-right: 0 !important; }
    .header .sub-header .title {
      font-size: 46px;
      font-weight: 600;
      color: #FFFFFF; }
    .header .sub-header .breadcrumb {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      background-color: transparent;
      padding: 16px 0; }
      @media only screen and (max-width: 768px) {
        .header .sub-header .breadcrumb {
          display: none; } }
      .header .sub-header .breadcrumb > li a {
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600; }
      .header .sub-header .breadcrumb > li::after {
        content: " / ";
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        padding: 0 8px; }
      .header .sub-header .breadcrumb > li:last-child a {
        color: #DA212E; }
      .header .sub-header .breadcrumb > li:last-child::after {
        content: ' '; }
    .header .sub-header .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 180px; }
      @media only screen and (max-width: 768px) {
        .header .sub-header .overlay {
          height: 150px; } }
      .header .sub-header .overlay::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 180px;
        background-color: rgba(34, 34, 34, 0.7); }
        @media only screen and (max-width: 768px) {
          .header .sub-header .overlay::after {
            height: 150px; } }
      .header .sub-header .overlay > img {
        width: 100%;
        height: 180px;
        object-fit: cover; }
        @media only screen and (max-width: 768px) {
          .header .sub-header .overlay > img {
            height: 150px; } }

.select-field {
  width: 100%;
  height: 50px;
  border: 1px solid #D1D1D1;
  border-radius: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  position: relative;
  float: left; }
  .select-field::after {
    content: ' ';
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #888888;
    border-left: 2px solid #888888;
    padding: 4px;
    transform: rotate(-45deg);
    margin-left: 8px;
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 18px;
    right: 22px; }
  .select-field > a {
    height: 100%;
    color: #333333;
    font-size: 14px;
    display: flex;
    align-items: center; }
    .select-field > a > span {
      display: none !important; }
    .select-field > a::after {
      display: none !important; }
  .select-field .dropdown-menu {
    max-height: 200px;
    overflow: auto; }
  .select-field > div {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    width: 100%;
    border: none; }
    .select-field > div > button {
      padding: 10px 16px; }
      .select-field > div > button:hover {
        background-color: #f8f8f8; }
  .select-field:hover {
    border: 1px solid #DA212E; }
    .select-field:hover > ul {
      display: block; }

.range-slider {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  float: left; }
  .range-slider .rc-slider {
    float: left;
    width: 100%; }

.rc-slider-tooltip-inner {
  background-color: transparent !important;
  color: #888888 !important;
  box-shadow: none !important;
  font-size: 18px !important;
  padding-top: 20px !important; }
  @media only screen and (max-width: 768px) {
    .rc-slider-tooltip-inner {
      padding-top: 6px !important; } }

.rc-slider-tooltip {
  z-index: 9999;
  visibility: visible; }

.react-calendar__tile--active {
  background: #DA212E !important;
  border-radius: 50%; }

.react-calendar {
  border: none !important; }

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
  display: none; }

.react-calendar__navigation {
  display: block !important;
  position: relative;
  margin-bottom: 0 !important; }

.react-calendar__navigation__prev-button {
  position: absolute;
  top: 0;
  right: 40px;
  font-size: 32px; }

.react-calendar__navigation__next-button {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 32px; }

.react-calendar__navigation__label {
  font-size: 18px;
  text-transform: uppercase; }

.search-form {
  margin-top: -110px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  padding-left: 0 !important;
  padding-right: 0 !important; }
  @media only screen and (max-width: 768px) {
    .search-form {
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 0;
      width: 100%;
      height: 100%;
      height: 100vh;
      background-color: #222222;
      z-index: 9999;
      overflow-x: hidden; } }
  .search-form > h3 {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 22px;
    color: #FFFFFF; }
    @media only screen and (max-width: 768px) {
      .search-form > h3 {
        width: 80%;
        font-size: 18px;
        padding: 20px 15px 30px 15px;
        margin-bottom: 0; } }
  .search-form .mdi-close {
    color: #FFFFFF;
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px; }
  .search-form .num-results {
    color: #DA212E;
    padding-top: 10px;
    text-align: right;
    font-weight: 600;
    font-size: 16px; }
    .search-form .num-results .isvg {
      margin-right: 8px; }
  .search-form .tabs .btab {
    background-color: #DA212E;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    border: 0;
    padding: 10px 25px;
    margin-right: 2px;
    outline: none;
    width: auto;
    float: left;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      .search-form .tabs .btab {
        font-size: 10px;
        padding: 10px 0;
        width: 33.33%;
        margin-right: 0;
        text-align: center;
        border-right: 2px solid #222222; }
        .search-form .tabs .btab:last-child {
          border-right: 0; } }
  .search-form .tabs .active {
    background-color: #FFFFFF;
    color: #333333; }
  .search-form form {
    /*background-color: $white;
        padding: 28px 30px 0 30px;*/ }
  .search-form .form-wrap {
    background-color: #FFFFFF;
    padding: 28px 30px 0 30px; }
    @media only screen and (max-width: 768px) {
      .search-form .form-wrap {
        height: 100%;
        height: 100vh;
        height: calc(100vh - 127px);
        padding-bottom: 64px;
        overflow-y: scroll; } }
  .search-form .buttons {
    display: flex;
    justify-content: flex-end; }
    .search-form .buttons .clear-btn {
      color: #DA212E;
      font-size: 12px;
      background-color: transparent;
      text-transform: uppercase;
      padding: 0 16px; }
  .search-form .input-wrap {
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px; }
    .search-form .input-wrap label {
      text-transform: uppercase;
      font-size: 12px;
      color: #9F9F9F;
      margin-top: 12px; }
    .search-form .input-wrap select {
      width: 100%;
      height: 50px;
      float: left;
      border: 1px solid #D1D1D1;
      border-radius: 25px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      cursor: pointer; }
      .search-form .input-wrap select:hover {
        border: 1px solid #DA212E; }
    .search-form .input-wrap .button {
      float: left;
      border-radius: 25px;
      border: 0;
      outline: none; }
    .search-form .input-wrap .black-btn {
      background-color: #2F3234;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      width: 150px;
      text-transform: uppercase; }
    .search-form .input-wrap .clear-btn {
      color: #DA212E !important;
      font-size: 12px;
      padding-top: 10px; }
    .search-form .input-wrap .right-chevron {
      display: flex;
      align-items: center;
      justify-content: center; }
      .search-form .input-wrap .right-chevron::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 4px solid #FFFFFF;
        border-left: 4px solid #FFFFFF;
        padding: 4px;
        transform: rotate(-135deg);
        margin-left: 8px;
        width: 10px;
        height: 10px;
        display: block;
        float: left; }
  .search-form .range-slider > span {
    float: left;
    width: 20%;
    font-size: 18px;
    color: #333333;
    font-weight: 600; }
  .search-form .range-slider .rc-slider {
    float: left;
    width: 80%; }

.filter-form {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  padding: 20px 20px 0 20px;
  border-top: 5px solid #DA212E; }
  @media only screen and (max-width: 768px) {
    .filter-form {
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 0;
      width: 100%;
      height: 100%;
      height: 100vh;
      background-color: #FFFFFF;
      z-index: 9999;
      overflow-x: hidden; } }
  .filter-form > h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 22px;
    color: #333333; }
    @media only screen and (max-width: 768px) {
      .filter-form > h3 {
        width: 80%;
        font-size: 18px;
        padding: 20px 15px 30px 15px;
        margin-bottom: 0; } }
  .filter-form .mdi-close {
    color: #FFFFFF;
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 24px; }
  .filter-form .num-results {
    color: #DA212E;
    padding-top: 10px;
    text-align: right;
    font-weight: 600;
    font-size: 16px; }
    .filter-form .num-results .isvg {
      margin-right: 8px; }
  .filter-form .tabs .btab {
    background-color: #DA212E;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    border: 0;
    padding: 10px 25px;
    margin-right: 2px;
    outline: none; }
    @media only screen and (max-width: 768px) {
      .filter-form .tabs .btab {
        font-size: 10px;
        padding: 10px 0;
        width: 33.33%;
        margin-right: 0;
        text-align: center;
        border-right: 2px solid #222222; }
        .filter-form .tabs .btab:last-child {
          border-right: 0; } }
  .filter-form .tabs .active {
    background-color: #FFFFFF;
    color: #333333; }
  .filter-form form {
    /*background-color: $white;
        padding: 28px 30px 0 30px;*/ }
  .filter-form .form-wrap {
    background-color: #FFFFFF;
    padding: 28px 30px 0 30px; }
    @media only screen and (max-width: 768px) {
      .filter-form .form-wrap {
        height: 100%;
        height: 100vh;
        height: calc(100vh - 127px);
        padding-bottom: 64px;
        overflow-y: scroll; } }
  .filter-form .buttons {
    text-align: center;
    width: 100%; }
    .filter-form .buttons .clear-btn {
      color: #DA212E;
      font-size: 12px;
      background-color: transparent;
      text-transform: uppercase;
      padding: 0 16px;
      float: none !important;
      margin: 0 auto; }
    .filter-form .buttons .black-btn {
      float: none !important;
      margin: 0 auto; }
  .filter-form .input-wrap {
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px; }
    .filter-form .input-wrap label {
      text-transform: uppercase;
      font-size: 12px;
      color: #9F9F9F;
      margin-top: 12px; }
    .filter-form .input-wrap select {
      width: 100%;
      height: 50px;
      float: left;
      border: 1px solid #D1D1D1;
      border-radius: 25px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      cursor: pointer; }
      .filter-form .input-wrap select:hover {
        border: 1px solid #DA212E; }
    .filter-form .input-wrap .button {
      float: left;
      border-radius: 25px;
      border: 0;
      outline: none; }
    .filter-form .input-wrap .black-btn {
      background-color: #2F3234;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      width: 150px;
      text-transform: uppercase; }
    .filter-form .input-wrap .clear-btn {
      color: #DA212E !important;
      font-size: 12px;
      padding-top: 10px; }
    .filter-form .input-wrap .right-chevron {
      display: flex;
      align-items: center;
      justify-content: center; }
      .filter-form .input-wrap .right-chevron::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 4px solid #FFFFFF;
        border-left: 4px solid #FFFFFF;
        padding: 4px;
        transform: rotate(-135deg);
        margin-left: 8px;
        width: 10px;
        height: 10px;
        display: block;
        float: left; }
  .filter-form .range-slider {
    display: unset;
    margin-bottom: 32px;
    float: left; }
    .filter-form .range-slider > span {
      float: left;
      width: 100%;
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 8px; }
    .filter-form .range-slider .rc-slider {
      float: left;
      width: 100%;
      width: calc(100% - 12px);
      margin: 0 6px; }

.service-form {
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  border-top: 5px solid #DA212E;
  padding-left: 0 !important;
  padding-right: 0 !important; }
  .service-form h3 {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 22px; }
  .service-form form {
    background-color: #FFFFFF;
    padding: 28px 30px 0 30px; }
  .service-form .buttons {
    display: flex;
    align-items: center;
    height: 50px; }
    .service-form .buttons .clear-btn {
      color: #DA212E;
      font-size: 12px;
      background-color: transparent;
      text-transform: uppercase;
      padding: 0 16px; }
  .service-form .input-wrap {
    width: 100%;
    float: left;
    position: relative;
    margin-bottom: 20px; }
    .service-form .input-wrap label {
      text-transform: uppercase;
      font-size: 12px;
      color: #9F9F9F;
      margin-top: 12px; }
    .service-form .input-wrap input {
      width: 100%;
      height: 50px;
      float: left;
      border: 1px solid #D1D1D1;
      border-radius: 25px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
      cursor: pointer;
      padding: 0 16px; }
      .service-form .input-wrap input:hover {
        border: 1px solid #DA212E; }
    .service-form .input-wrap .button {
      float: left;
      border-radius: 25px;
      border: 0;
      outline: none; }
    .service-form .input-wrap .black-btn {
      background-color: #2F3234;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 600;
      height: 50px;
      width: 150px;
      text-transform: uppercase; }
    .service-form .input-wrap .right-chevron {
      display: flex;
      align-items: center;
      justify-content: center; }
      .service-form .input-wrap .right-chevron::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 4px solid #FFFFFF;
        border-left: 4px solid #FFFFFF;
        padding: 4px;
        transform: rotate(-135deg);
        margin-left: 8px;
        width: 10px;
        height: 10px;
        display: block;
        float: left; }

.contact-form h3 {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 22px; }

.contact-form form {
  background-color: #FFFFFF;
  padding: 28px 30px 0 30px; }

.contact-form .buttons {
  display: flex;
  align-items: center;
  height: 50px; }
  .contact-form .buttons .clear-btn {
    color: #DA212E;
    font-size: 12px;
    background-color: transparent;
    text-transform: uppercase;
    padding: 0 16px; }

.contact-form .input-wrap {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 20px; }
  .contact-form .input-wrap label {
    text-transform: uppercase;
    font-size: 12px;
    color: #9F9F9F;
    margin-top: 12px; }
  .contact-form .input-wrap input {
    width: 100%;
    height: 50px;
    float: left;
    border: 1px solid #D1D1D1;
    border-radius: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    padding: 0 16px; }
    .contact-form .input-wrap input:hover {
      border: 1px solid #DA212E; }
  .contact-form .input-wrap textarea {
    width: 100%;
    height: 200px;
    float: left;
    border: 1px solid #D1D1D1;
    border-radius: 25px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    padding: 10px 16px; }
    .contact-form .input-wrap textarea:hover {
      border: 1px solid #DA212E; }
  .contact-form .input-wrap .button {
    float: left;
    border-radius: 25px;
    border: 0;
    outline: none; }
  .contact-form .input-wrap .black-btn {
    background-color: #2F3234;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    height: 50px;
    width: 150px;
    text-transform: uppercase; }
  .contact-form .input-wrap .right-chevron {
    display: flex;
    align-items: center;
    justify-content: center; }
    .contact-form .input-wrap .right-chevron::after {
      content: ' ';
      border-top: 0;
      border-right: 0;
      border-bottom: 4px solid #FFFFFF;
      border-left: 4px solid #FFFFFF;
      padding: 4px;
      transform: rotate(-135deg);
      margin-left: 8px;
      width: 10px;
      height: 10px;
      display: block;
      float: left; }

.article-box {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }
  .article-box > img {
    width: 100%;
    height: 160px;
    object-fit: cover; }
    @media only screen and (max-width: 768px) {
      .article-box > img {
        height: 100px; } }
  .article-box .content {
    float: left;
    width: 100%; }
    .article-box .content .title {
      font-size: 18px;
      color: #2F3234;
      font-weight: 600;
      padding: 12px 12px;
      margin-bottom: 0;
      height: 80px; }
      @media only screen and (max-width: 768px) {
        .article-box .content .title {
          font-size: 16px;
          padding: 8px 8px; } }
    .article-box .content .attrs {
      float: left;
      width: 100%;
      margin: 0;
      padding: 0 12px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      @media only screen and (max-width: 768px) {
        .article-box .content .attrs {
          padding: 0 8px; } }
      .article-box .content .attrs > li {
        float: left;
        color: #888888;
        font-size: 12px; }
        .article-box .content .attrs > li .isvg {
          margin-right: 4px; }
          .article-box .content .attrs > li .isvg svg {
            fill: #9F9F9F !important;
            height: 16px;
            width: 16px; }
          .article-box .content .attrs > li .isvg .cls-1, .article-box .content .attrs > li .isvg .cls-3 {
            fill: #9F9F9F !important; }
        .article-box .content .attrs > li:last-child .cls-1, .article-box .content .attrs > li:last-child .cls-3 {
          fill: none !important; }
        @media only screen and (max-width: 768px) {
          .article-box .content .attrs > li {
            text-align: center; }
            .article-box .content .attrs > li .isvg {
              width: 100%;
              float: left; } }
    .article-box .content .divider {
      height: 1px;
      float: left;
      width: 100%;
      background-color: #9F9F9F;
      margin: 12px 0; }
      @media only screen and (max-width: 768px) {
        .article-box .content .divider {
          margin: 8px 0; } }
    .article-box .content .price {
      float: left;
      color: #DA212E;
      font-weight: 600;
      font-size: 20px;
      padding: 0 12px 12px 12px; }
      @media only screen and (max-width: 768px) {
        .article-box .content .price {
          padding: 0 8px 8px 8px; } }

.car-animation {
  position: relative;
  animation-name: car-animation;
  animation-duration: 2s; }

@keyframes car-animation {
  from {
    top: -50px;
    right: -80px;
    transform: scale(0.7); }
  to {
    top: 0px;
    right: 0;
    transform: scale(1); } }

.top-bottom-animation {
  position: relative;
  animation-name: top-bottom;
  animation-duration: 1s; }

@keyframes top-bottom {
  from {
    top: -200px; }
  to {
    top: 0px; } }

.dropdown-animation {
  animation-name: dropdown-animation;
  animation-duration: 0.2s; }

@keyframes dropdown-animation {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(48px);
    opacity: 1; } }

@keyframes bubble-animation {
  0% {
    border: 10px solid rgba(255, 255, 255, 0); }
  50% {
    border: 20px solid rgba(255, 255, 255, 0.5); }
  100% {
    border: 10px solid rgba(255, 255, 255, 0); } }

.bubble-animation {
  animation-iteration-count: infinite;
  animation-name: bubble-animation;
  animation-duration: 2s; }

@keyframes active-menu-animation {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    transform: translate3d(70%, 10vh, 0); } }

.active-menu-animation {
  transform: translate3d(70%, 10vh, 0);
  position: fixed;
  width: 100% !important;
  position: fixed !important;
  height: 80vh !important;
  overflow-x: auto;
  overflow-y: scroll;
  animation-name: active-menu-animation;
  animation-duration: 1s;
  -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }

.home-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .home-wrap .section {
    padding: 60px 0;
    float: left;
    width: 100%; }
    .home-wrap .section .sub-title {
      margin-bottom: 45px;
      padding-top: 32px; }
      .home-wrap .section .sub-title::after {
        content: ' ';
        background-image: url(./assets/images/title.png);
        display: block;
        float: left;
        height: 4px;
        width: 100px;
        position: absolute;
        top: 0px;
        left: 50%;
        margin-left: -50px; }
      .home-wrap .section .sub-title h2 {
        font-size: 46px;
        font-weight: 600; }
        @media only screen and (max-width: 768px) {
          .home-wrap .section .sub-title h2 {
            font-size: 28px; } }
      .home-wrap .section .sub-title p {
        font-size: 18px; }
  .home-wrap .home-slider {
    width: 100%;
    height: 670px;
    float: left; }
    @media only screen and (max-width: 768px) {
      .home-wrap .home-slider {
        height: 160px; } }
    .home-wrap .home-slider .carousel-item > img {
      width: 100%;
      height: 100%;
      max-height: 670px;
      object-fit: cover; }
      @media only screen and (max-width: 768px) {
        .home-wrap .home-slider .carousel-item > img {
          max-height: 160px; } }
    .home-wrap .home-slider .carousel-dots {
      position: absolute;
      top: 860px;
      left: 0;
      width: 100%;
      z-index: 3; }
      .home-wrap .home-slider .carousel-dots .carousel-indicators {
        width: 100%;
        justify-content: flex-end;
        position: unset;
        left: unset;
        right: unset;
        top: unset;
        bottom: unset;
        margin: 0; }
        .home-wrap .home-slider .carousel-dots .carousel-indicators > li {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #D1D1D1; }
        .home-wrap .home-slider .carousel-dots .carousel-indicators .active {
          background-color: #DA212E; }
    .home-wrap .home-slider .slide-content {
      position: absolute;
      padding-top: 80px;
      top: 0;
      left: 0;
      width: 100%;
      height: 670px;
      z-index: 1;
      z-index: 2; }
      @media only screen and (max-width: 768px) {
        .home-wrap .home-slider .slide-content {
          height: 160px;
          padding-top: 20px; } }
      .home-wrap .home-slider .slide-content .bubble {
        position: absolute;
        top: 0;
        right: 0px;
        border-radius: 50%;
        width: 300px;
        height: 300px;
        border: 20px solid rgba(255, 255, 255, 0.1); }
        @media only screen and (max-width: 768px) {
          .home-wrap .home-slider .slide-content .bubble {
            display: none; } }
      .home-wrap .home-slider .slide-content .image {
        margin-top: 0px;
        width: 600px; }
        @media only screen and (max-width: 768px) {
          .home-wrap .home-slider .slide-content .image {
            width: 200px; } }
      .home-wrap .home-slider .slide-content h3 {
        color: #FFFFFF;
        font-size: 64px;
        font-weight: 600;
        float: left;
        background-color: #222222;
        margin-bottom: 0; }
        @media only screen and (max-width: 768px) {
          .home-wrap .home-slider .slide-content h3 {
            font-size: 22px; } }
        .home-wrap .home-slider .slide-content h3:nth-child(1) {
          padding: 10px 14px 0 14px;
          background-color: #222222;
          margin-top: 120px; }
          @media only screen and (max-width: 768px) {
            .home-wrap .home-slider .slide-content h3:nth-child(1) {
              margin-top: 0;
              padding: 6px 10px 0 10px; } }
        .home-wrap .home-slider .slide-content h3:nth-child(2) {
          padding: 0 14px 0 14px;
          background-color: #222222; }
          @media only screen and (max-width: 768px) {
            .home-wrap .home-slider .slide-content h3:nth-child(2) {
              padding: 0 10px 0 10px; } }
        .home-wrap .home-slider .slide-content h3:nth-child(3) {
          padding: 0 14px 10px 14px;
          background-color: #FFFFFF;
          color: #DA212E;
          clear: both; }
          @media only screen and (max-width: 768px) {
            .home-wrap .home-slider .slide-content h3:nth-child(3) {
              min-width: 154px;
              float: left;
              clear: both;
              padding: 0 10px 6px 12px; } }
  .home-wrap .form-overlay {
    height: 130px;
    background: #222222;
    width: 100%;
    margin-top: 670px; }
    @media only screen and (max-width: 768px) {
      .home-wrap .form-overlay {
        margin-top: 160px; } }
  @media only screen and (max-width: 768px) {
    .home-wrap .latest-cars-section {
      padding-top: 30px; } }
  .home-wrap .latest-cars-section .section-title {
    margin-left: -15px;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 24px; }
    @media only screen and (max-width: 768px) {
      .home-wrap .latest-cars-section .section-title {
        margin-left: 0; } }
    .home-wrap .latest-cars-section .section-title > span {
      color: #DA212E; }
  .home-wrap .solutions-section {
    position: relative;
    padding: 100px 0; }
    @media only screen and (max-width: 768px) {
      .home-wrap .solutions-section {
        padding: 20px 0; } }
    .home-wrap .solutions-section .overlay {
      width: 100%;
      height: 460px;
      background-color: #2F3234;
      position: absolute;
      top: 0;
      left: 0; }
      .home-wrap .solutions-section .overlay::after {
        content: ' ';
        width: 100%;
        height: 460px;
        display: block;
        background-color: rgba(34, 34, 34, 0.7);
        position: absolute;
        top: 0;
        left: 0; }
      .home-wrap .solutions-section .overlay .overlay-image {
        width: 100%;
        height: 460px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; }
    .home-wrap .solutions-section article {
      background-color: rgba(218, 33, 46, 0.8);
      float: left;
      width: 100%;
      padding: 40px 20px; }
      @media only screen and (max-width: 768px) {
        .home-wrap .solutions-section article {
          margin-bottom: 15px;
          padding: 20px 20px; } }
      .home-wrap .solutions-section article > div {
        width: 80%;
        float: left;
        padding-left: 52px; }
        .home-wrap .solutions-section article > div > h3 {
          font-size: 32px;
          font-weight: 800;
          margin-bottom: 12px;
          color: #FFFFFF; }
          @media only screen and (max-width: 768px) {
            .home-wrap .solutions-section article > div > h3 {
              font-size: 22px; } }
        .home-wrap .solutions-section article > div > p {
          font-size: 18px;
          color: #FFFFFF;
          float: left;
          margin-bottom: 0; }
          @media only screen and (max-width: 768px) {
            .home-wrap .solutions-section article > div > p {
              font-size: 14px; } }
      .home-wrap .solutions-section article > img {
        width: 20%;
        float: left; }
  .home-wrap .about-us-section .section-title {
    color: #2F3234;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 22px;
    float: left;
    position: relative; }
    .home-wrap .about-us-section .section-title::after {
      content: ' ';
      display: block;
      float: left;
      width: 30px;
      height: 2px;
      background-color: #DA212E;
      position: absolute;
      bottom: -12px; }
  .home-wrap .about-us-section .section-subtitle {
    color: #FFFFFF;
    font-size: 18px;
    color: #888888;
    margin-bottom: 54px; }
  .home-wrap .about-us-section article {
    float: left;
    width: 100%;
    margin-bottom: 30px;
    padding: 20px;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16); }
    .home-wrap .about-us-section article .image {
      width: 22%;
      float: left; }
    .home-wrap .about-us-section article > div {
      width: 78%;
      float: left; }
      .home-wrap .about-us-section article > div > h3 {
        font-size: 22px;
        color: #2F3234;
        font-weight: 600; }
      .home-wrap .about-us-section article > div > p {
        color: #888888;
        font-size: 14px;
        margin-bottom: 0; }
  .home-wrap .about-us-section .overlay {
    width: 530px;
    height: 430px;
    background-color: #2F3234;
    border-radius: 100px;
    -ms-transform: rotate(17deg);
    /* IE 9 */
    -ms-transform-origin: 20% 40%;
    /* IE 9 */
    -webkit-transform: rotate(17deg);
    /* Safari 3-8 */
    -webkit-transform-origin: 20% 40%;
    /* Safari 3-8 */
    transform: rotate(17deg);
    transform-origin: 0% 100%;
    overflow: hidden;
    position: relative;
    position: absolute;
    top: 0;
    left: 0; }
    .home-wrap .about-us-section .overlay .overlay-image {
      width: 750px;
      height: 700px;
      position: absolute;
      top: -100px;
      left: -50px;
      transform: rotate(-17deg);
      object-fit: cover; }
  .home-wrap .news-section {
    position: relative; }
    .home-wrap .news-section .overlay {
      width: 100%;
      height: 680px;
      background-color: #2F3234;
      position: absolute;
      top: 0;
      left: 0; }
      @media only screen and (max-width: 768px) {
        .home-wrap .news-section .overlay {
          height: 550px; } }
      .home-wrap .news-section .overlay::after {
        content: ' ';
        width: 100%;
        height: 680px;
        display: block;
        background-color: rgba(34, 34, 34, 0.7);
        position: absolute;
        top: 0;
        left: 0; }
        @media only screen and (max-width: 768px) {
          .home-wrap .news-section .overlay::after {
            height: 550px; } }
      .home-wrap .news-section .overlay .overlay-image {
        width: 100%;
        height: 680px;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover; }
        @media only screen and (max-width: 768px) {
          .home-wrap .news-section .overlay .overlay-image {
            height: 550px; } }
    .home-wrap .news-section .sub-title {
      color: #FFFFFF; }
    .home-wrap .news-section .button-border {
      background-color: #FFFFFF;
      border: 3px solid #DA212E;
      color: #2F3234 !important;
      font-size: 16px;
      height: 50px; }
    .home-wrap .news-section article {
      float: left;
      width: 100%;
      margin-bottom: 30px;
      -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      background-color: #FFFFFF; }
      .home-wrap .news-section article > img {
        width: 100%;
        height: 180px; }
      .home-wrap .news-section article .content {
        padding: 20px; }
        .home-wrap .news-section article .content .date {
          font-size: 16px;
          color: #888888;
          margin-bottom: 12px; }
        .home-wrap .news-section article .content .title {
          float: left;
          width: 100%;
          font-size: 20px;
          color: #2F3234;
          font-weight: 600;
          margin-bottom: 16px; }
        .home-wrap .news-section article .content > p {
          font-size: 16px;
          color: #888888; }
        .home-wrap .news-section article .content .read-more {
          color: #DA212E;
          font-size: 16px;
          cursor: pointer;
          border-bottom: 2px solid #DA212E; }
        .home-wrap .news-section article .content .comments {
          float: right;
          color: #888888;
          font-weight: 600;
          font-size: 16px; }
  .home-wrap .gallery-section {
    padding-bottom: 0; }
    .home-wrap .gallery-section .section-title {
      color: #2F3234;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 22px;
      float: left;
      position: relative;
      width: 100%;
      text-align: center;
      padding-top: 40px; }
      .home-wrap .gallery-section .section-title::after {
        content: ' ';
        display: block;
        float: left;
        height: 40px;
        width: 2px;
        background-color: #DA212E;
        position: absolute;
        top: -12px;
        left: 50%;
        margin-left: -1px; }
      .home-wrap .gallery-section .section-title > span {
        color: #DA212E; }
    .home-wrap .gallery-section .image {
      width: 20%;
      float: left;
      position: relative;
      height: 250px; }
      @media only screen and (max-width: 768px) {
        .home-wrap .gallery-section .image {
          width: 50%; }
          .home-wrap .gallery-section .image:nth-child(4n) {
            display: none; }
          .home-wrap .gallery-section .image:nth-child(4n+1) {
            display: none; } }
      @media only screen and (max-width: 768px) {
        .home-wrap .gallery-section .image {
          width: 50%; } }
      .home-wrap .gallery-section .image > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-fit: cover; }
      .home-wrap .gallery-section .image .hover {
        display: none; }
      .home-wrap .gallery-section .image:hover .hover {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(218, 33, 46, 0.8);
        width: 100%;
        height: 100%; }
        .home-wrap .gallery-section .image:hover .hover > div {
          position: absolute;
          bottom: 26px;
          width: 100%;
          padding: 0 30px;
          display: flex;
          align-items: center; }
          .home-wrap .gallery-section .image:hover .hover > div .text {
            width: 70%;
            float: left; }
            .home-wrap .gallery-section .image:hover .hover > div .text > h6 {
              color: #FFFFFF;
              font-size: 12px;
              float: left;
              clear: both; }
            .home-wrap .gallery-section .image:hover .hover > div .text > h3 {
              font-size: 18px;
              font-weight: 600;
              color: #FFFFFF;
              float: left;
              clear: both; }
          .home-wrap .gallery-section .image:hover .hover > div .magnify {
            margin-left: auto;
            border-radius: 50%;
            background-color: #FFFFFF;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .home-wrap .gallery-section .image:hover .hover > div .magnify > i {
              color: #DA212E;
              font-size: 24px; }
  .home-wrap .reviews-section .carousel-control-prev, .home-wrap .reviews-section .carousel-control-next {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #E8E8E8;
    top: 50%;
    margin-top: -25px;
    cursor: pointer;
    opacity: 1; }
    .home-wrap .reviews-section .carousel-control-prev:hover, .home-wrap .reviews-section .carousel-control-next:hover {
      background-color: #DA212E; }
  .home-wrap .reviews-section .prev-review, .home-wrap .reviews-section .next-review {
    padding-top: 14px;
    position: relative; }
    .home-wrap .reviews-section .prev-review::after, .home-wrap .reviews-section .next-review::after {
      position: absolute;
      top: 14px;
      left: 15px;
      content: ' ';
      display: block;
      border-radius: 50%;
      width: 64px;
      height: 64px;
      background-color: rgba(255, 255, 255, 0.8); }
    .home-wrap .reviews-section .prev-review > img, .home-wrap .reviews-section .next-review > img {
      width: 64px;
      height: 64px;
      border-radius: 50%; }
  .home-wrap .reviews-section .curr-review {
    text-align: center; }
    .home-wrap .reviews-section .curr-review .name {
      margin-top: 25px;
      width: 100%;
      float: left;
      font-size: 18px;
      font-weight: 600;
      color: #2F3234; }
    .home-wrap .reviews-section .curr-review .type {
      width: 100%;
      float: left;
      font-size: 14px;
      color: #888888; }
    .home-wrap .reviews-section .curr-review > img {
      width: 92px;
      height: 92px;
      border-radius: 50%; }
  .home-wrap .reviews-section .slide-content {
    text-align: center;
    font-size: 24px;
    color: #2F3234;
    margin-bottom: 36px; }
  .home-wrap .plan-service-section {
    background-color: #F5F5F5; }
  .home-wrap .map-section {
    position: relative;
    padding: 0 0 40px 0; }
    .home-wrap .map-section .container-fluid {
      padding: 0 !important; }
    .home-wrap .map-section .map {
      width: 100%;
      height: 500px; }
      @media only screen and (max-width: 768px) {
        .home-wrap .map-section .map {
          height: 200px; } }
    .home-wrap .map-section .section-title {
      color: #FFFFFF;
      font-size: 46px;
      font-weight: 600;
      margin-bottom: 22px; }
    .home-wrap .map-section .section-subtitle {
      color: #FFFFFF;
      font-size: 18px;
      color: #D1D1D1; }
    .home-wrap .map-section .see-all {
      text-align: right;
      padding-top: 25px;
      font-size: 14px;
      color: #DA212E;
      cursor: pointer; }
      .home-wrap .map-section .see-all::before {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 1px solid #DA212E;
        border-left: 1px solid #DA212E;
        padding: 4px;
        transform: rotate(-135deg);
        margin-right: 8px;
        width: 10px;
        height: 10px;
        display: inline-block; }
  .home-wrap .brands-section .section-title {
    color: #2F3234;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 22px;
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
    padding-top: 40px; }
    .home-wrap .brands-section .section-title::after {
      content: ' ';
      display: block;
      float: left;
      height: 40px;
      width: 2px;
      background-color: #DA212E;
      position: absolute;
      top: -12px;
      left: 50%;
      margin-left: -1px; }
    .home-wrap .brands-section .section-title > span {
      color: #DA212E; }
  .home-wrap .brands-section .section-subtitle {
    color: #888888;
    font-size: 18px;
    margin-bottom: 64px;
    position: relative;
    width: 100%;
    text-align: center; }
  .home-wrap .brands-section .brands {
    width: 100%;
    float: left;
    display: flex;
    justify-content: space-between; }
    .home-wrap .brands-section .brands > img {
      height: 64px; }
  .home-wrap .services-section .col-6 {
    border-left: 1px solid #D1D1D1; }
    .home-wrap .services-section .col-6:nth-of-type(2) {
      border-left: 0; }
  .home-wrap .services-section article {
    width: 100%;
    float: left;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .home-wrap .services-section article {
        margin-bottom: 30px; } }
    .home-wrap .services-section article > img {
      height: 52px;
      margin-bottom: 32px; }
    .home-wrap .services-section article h3 {
      font-size: 22px;
      color: #2F3234;
      font-weight: 600; }
    .home-wrap .services-section article p {
      color: #9F9F9F;
      font-size: 16px; }
    .home-wrap .services-section article .button {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      background-color: #E8E8E8;
      float: none;
      margin: 0 auto; }
      .home-wrap .services-section article .button:hover {
        background-color: #DA212E; }
      .home-wrap .services-section article .button::after {
        content: ' ';
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid #FFFFFF;
        border-left: 2px solid #FFFFFF;
        padding: 4px;
        transform: rotate(-135deg);
        width: 10px;
        height: 10px;
        display: block;
        margin-left: -2px; }

.inventory-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .inventory-wrap .page-wrap {
    float: left;
    width: 100%;
    padding-top: 50px; }
    @media only screen and (max-width: 768px) {
      .inventory-wrap .page-wrap {
        padding-top: 25px; } }
    .inventory-wrap .page-wrap .top {
      width: 100%;
      float: left;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 768px) {
        .inventory-wrap .page-wrap .top {
          display: block; } }
      .inventory-wrap .page-wrap .top h3 {
        color: #333333;
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        float: left;
        width: auto; }
        @media only screen and (max-width: 768px) {
          .inventory-wrap .page-wrap .top h3 {
            width: 100%; } }
      .inventory-wrap .page-wrap .top .sort {
        float: right;
        margin-left: auto;
        display: flex;
        align-items: center; }
        @media only screen and (max-width: 768px) {
          .inventory-wrap .page-wrap .top .sort {
            float: left;
            width: 100%;
            justify-content: center; } }
        .inventory-wrap .page-wrap .top .sort > span {
          font-size: 14px;
          color: #333333;
          float: left;
          padding-right: 20px; }
        .inventory-wrap .page-wrap .top .sort .btn {
          width: 40px;
          height: 40px;
          float: left;
          border-radius: 0;
          display: flex;
          align-items: center;
          justify-content: center; }
          .inventory-wrap .page-wrap .top .sort .btn:hover {
            background-color: #F3F3F3; }
        .inventory-wrap .page-wrap .top .sort .select-field {
          float: left;
          width: 200px; }
    .inventory-wrap .page-wrap .articles {
      float: left;
      padding-top: 32px; }
    .inventory-wrap .page-wrap .pagination {
      float: left; }
      .inventory-wrap .page-wrap .pagination > li {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: #F3F3F3;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #333333;
        font-weight: 600;
        font-size: 16px;
        margin-right: 10px;
        cursor: pointer; }
        .inventory-wrap .page-wrap .pagination > li a {
          color: #333333;
          display: flex;
          width: 100%;
          height: 100%;
          align-items: center;
          justify-content: center; }
        .inventory-wrap .page-wrap .pagination > li:hover {
          color: #FFFFFF;
          text-decoration: none;
          background-color: #DA212E; }
          .inventory-wrap .page-wrap .pagination > li:hover a {
            text-decoration: none;
            color: #FFFFFF; }
      .inventory-wrap .page-wrap .pagination .break-me:hover {
        background-color: #F3F3F3;
        cursor: default; }
        .inventory-wrap .page-wrap .pagination .break-me:hover a {
          color: #333333; }
      .inventory-wrap .page-wrap .pagination .active {
        color: #FFFFFF;
        background-color: #DA212E; }
        .inventory-wrap .page-wrap .pagination .active a {
          color: #FFFFFF; }
      .inventory-wrap .page-wrap .pagination .previous {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; }
        .inventory-wrap .page-wrap .pagination .previous::after {
          content: ' ';
          border-top: 0;
          border-right: 0;
          border-bottom: 2px solid #333333;
          border-left: 2px solid #333333;
          padding: 4px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          margin-left: -35px;
          width: 10px;
          height: 10px;
          display: block; }
        .inventory-wrap .page-wrap .pagination .previous:hover::after {
          border-bottom: 2px solid #FFFFFF;
          border-left: 2px solid #FFFFFF; }
      .inventory-wrap .page-wrap .pagination .next {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center; }
        .inventory-wrap .page-wrap .pagination .next::after {
          content: ' ';
          border-top: 0;
          border-right: 0;
          border-bottom: 2px solid #333333;
          border-left: 2px solid #333333;
          padding: 4px;
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
          margin-left: -35px;
          width: 10px;
          height: 10px;
          display: block; }
        .inventory-wrap .page-wrap .pagination .next:hover::after {
          border-bottom: 2px solid #FFFFFF;
          border-left: 2px solid #FFFFFF; }
  .inventory-wrap .map-section {
    position: relative;
    padding: 86px 0 40px 0; }
    .inventory-wrap .map-section .container-fluid {
      padding: 0 !important; }
    .inventory-wrap .map-section .map {
      width: 100%;
      height: 500px; }
      @media only screen and (max-width: 768px) {
        .inventory-wrap .map-section .map {
          height: 200px; } }

.solutions-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .solutions-wrap .page-wrap {
    float: left;
    width: 100%;
    padding-top: 50px; }
    .solutions-wrap .page-wrap .services-section {
      background-color: #F5F5F5; }
      .solutions-wrap .page-wrap .services-section .col-6 {
        border-left: 1px solid #D1D1D1; }
        .solutions-wrap .page-wrap .services-section .col-6:nth-of-type(2) {
          border-left: 0; }
      .solutions-wrap .page-wrap .services-section article {
        width: 100%;
        float: left;
        text-align: center; }
        @media only screen and (max-width: 768px) {
          .solutions-wrap .page-wrap .services-section article {
            margin-bottom: 30px; } }
        .solutions-wrap .page-wrap .services-section article > img {
          height: 52px;
          margin-bottom: 32px; }
        .solutions-wrap .page-wrap .services-section article h3 {
          font-size: 22px;
          color: #2F3234;
          font-weight: 600; }
        .solutions-wrap .page-wrap .services-section article p {
          color: #9F9F9F;
          font-size: 16px; }
        .solutions-wrap .page-wrap .services-section article .button {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
          background-color: #E8E8E8;
          float: none;
          margin: 0 auto; }
          .solutions-wrap .page-wrap .services-section article .button:hover {
            background-color: #DA212E; }
          .solutions-wrap .page-wrap .services-section article .button::after {
            content: ' ';
            border-top: 0;
            border-right: 0;
            border-bottom: 2px solid #FFFFFF;
            border-left: 2px solid #FFFFFF;
            padding: 4px;
            transform: rotate(-135deg);
            width: 10px;
            height: 10px;
            display: block;
            margin-left: -2px; }
    .solutions-wrap .page-wrap .achivments-section {
      position: relative;
      background-color: #F5F5F5; }
      .solutions-wrap .page-wrap .achivments-section .items > div {
        display: flex;
        align-items: center; }
        .solutions-wrap .page-wrap .achivments-section .items > div .isvg {
          float: left; }
        .solutions-wrap .page-wrap .achivments-section .items > div .text {
          float: left;
          padding-left: 30px; }
          .solutions-wrap .page-wrap .achivments-section .items > div .text > span {
            font-size: 36px;
            font-weight: 600;
            color: #333333;
            float: left;
            width: auto; }
            .solutions-wrap .page-wrap .achivments-section .items > div .text > span:last-child {
              font-size: 16px;
              font-weight: 400;
              clear: both; }
        .solutions-wrap .page-wrap .achivments-section .items > div .cls-1 {
          fill: #DA212E; }
        .solutions-wrap .page-wrap .achivments-section .items > div svg {
          height: 60px;
          width: 60px; }
        .solutions-wrap .page-wrap .achivments-section .items > div:last-child .cls-1 {
          fill: transparent; }
        .solutions-wrap .page-wrap .achivments-section .items > div:last-child .cls-2 {
          fill: #DA212E; }
        .solutions-wrap .page-wrap .achivments-section .items > div:last-child .cls-3 {
          stroke: #DA212E; }
    .solutions-wrap .page-wrap .solutions-section {
      position: relative; }
      .solutions-wrap .page-wrap .solutions-section article {
        background-color: #FFFFFF;
        border-top: 5px solid #DA212E;
        float: left;
        width: 100%;
        -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
        margin-bottom: 30px; }
        .solutions-wrap .page-wrap .solutions-section article > div {
          width: 60%;
          padding: 30px 15px 30px 30px;
          float: left; }
          .solutions-wrap .page-wrap .solutions-section article > div > h3 {
            font-size: 22px;
            font-weight: 800;
            margin-bottom: 12px; }
            .solutions-wrap .page-wrap .solutions-section article > div > h3 > span {
              color: #DA212E;
              float: left; }
          .solutions-wrap .page-wrap .solutions-section article > div > p {
            font-size: 18px;
            color: #888888;
            float: left;
            margin-bottom: 32px; }
        .solutions-wrap .page-wrap .solutions-section article > img {
          width: 40%;
          float: left; }
    .solutions-wrap .page-wrap .reviews-section .carousel-control-prev, .solutions-wrap .page-wrap .reviews-section .carousel-control-next {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #E8E8E8;
      top: 50%;
      margin-top: -25px;
      cursor: pointer;
      opacity: 1; }
      .solutions-wrap .page-wrap .reviews-section .carousel-control-prev:hover, .solutions-wrap .page-wrap .reviews-section .carousel-control-next:hover {
        background-color: #DA212E; }
    .solutions-wrap .page-wrap .reviews-section .prev-review, .solutions-wrap .page-wrap .reviews-section .next-review {
      padding-top: 14px;
      position: relative; }
      .solutions-wrap .page-wrap .reviews-section .prev-review::after, .solutions-wrap .page-wrap .reviews-section .next-review::after {
        position: absolute;
        top: 14px;
        left: 15px;
        content: ' ';
        display: block;
        border-radius: 50%;
        width: 64px;
        height: 64px;
        background-color: rgba(255, 255, 255, 0.8); }
      .solutions-wrap .page-wrap .reviews-section .prev-review > img, .solutions-wrap .page-wrap .reviews-section .next-review > img {
        width: 64px;
        height: 64px;
        border-radius: 50%; }
    .solutions-wrap .page-wrap .reviews-section .curr-review {
      text-align: center; }
      .solutions-wrap .page-wrap .reviews-section .curr-review .name {
        margin-top: 25px;
        width: 100%;
        float: left;
        font-size: 18px;
        font-weight: 600;
        color: #2F3234; }
      .solutions-wrap .page-wrap .reviews-section .curr-review .type {
        width: 100%;
        float: left;
        font-size: 14px;
        color: #888888; }
      .solutions-wrap .page-wrap .reviews-section .curr-review > img {
        width: 92px;
        height: 92px;
        border-radius: 50%; }
    .solutions-wrap .page-wrap .reviews-section .slide-content {
      text-align: center;
      font-size: 24px;
      color: #2F3234;
      margin-bottom: 36px; }

.gallery-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .gallery-wrap .page-wrap {
    float: left;
    width: 100%;
    padding-top: 50px; }
  .gallery-wrap .gallery-section {
    position: relative; }
    .gallery-wrap .gallery-section .gallery {
      width: 100%;
      float: left; }
      @media only screen and (max-width: 768px) {
        .gallery-wrap .gallery-section .gallery {
          margin: 0 !important; } }
      .gallery-wrap .gallery-section .gallery .image {
        width: 33.33%;
        position: relative;
        height: 250px; }
        @media only screen and (max-width: 768px) {
          .gallery-wrap .gallery-section .gallery .image {
            width: 50%; } }
        .gallery-wrap .gallery-section .gallery .image > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-fit: cover; }
        .gallery-wrap .gallery-section .gallery .image .hover {
          display: none; }
        .gallery-wrap .gallery-section .gallery .image:hover .hover {
          cursor: pointer;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(218, 33, 46, 0.8);
          width: 100%;
          height: 100%; }
          .gallery-wrap .gallery-section .gallery .image:hover .hover > div {
            position: absolute;
            bottom: 26px;
            width: 100%;
            padding: 0 30px;
            display: flex;
            align-items: center; }
            .gallery-wrap .gallery-section .gallery .image:hover .hover > div .text {
              width: 70%;
              float: left; }
              .gallery-wrap .gallery-section .gallery .image:hover .hover > div .text > h6 {
                color: #FFFFFF;
                font-size: 12px;
                float: left;
                clear: both; }
              .gallery-wrap .gallery-section .gallery .image:hover .hover > div .text > h3 {
                font-size: 18px;
                font-weight: 600;
                color: #FFFFFF;
                float: left;
                clear: both; }
            .gallery-wrap .gallery-section .gallery .image:hover .hover > div .magnify {
              margin-left: auto;
              border-radius: 50%;
              background-color: #FFFFFF;
              width: 36px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center; }
              .gallery-wrap .gallery-section .gallery .image:hover .hover > div .magnify > i {
                color: #DA212E;
                font-size: 24px; }

.detail-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .detail-wrap .page-wrap {
    float: left;
    width: 100%;
    padding-top: 50px; }
    @media only screen and (max-width: 768px) {
      .detail-wrap .page-wrap {
        padding-top: 0; } }
  .detail-wrap .detail-section {
    position: relative; }
    .detail-wrap .detail-section .preview {
      width: 100%;
      height: 410px; }
      @media only screen and (max-width: 768px) {
        .detail-wrap .detail-section .preview {
          height: 250px; } }
    .detail-wrap .detail-section .images {
      width: 100%;
      padding-top: 20px;
      overflow: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      cursor: grab;
      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;
      /* Non-prefixed version, currently*/ }
      .detail-wrap .detail-section .images .image {
        display: inline-block;
        margin-right: 10px;
        width: 150px;
        height: 100px;
        position: relative; }
        .detail-wrap .detail-section .images .image::after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.7); }
        .detail-wrap .detail-section .images .image > img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
          .detail-wrap .detail-section .images .image > img:last-child {
            margin-right: 0; }
      .detail-wrap .detail-section .images .active {
        position: relative; }
        .detail-wrap .detail-section .images .active::after {
          content: " ";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent; }
    .detail-wrap .detail-section .info {
      -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
      width: 100%;
      float: left;
      padding: 20px 15px; }
      .detail-wrap .detail-section .info > h1 {
        font-size: 32px;
        font-weight: 600;
        color: #333333; }
      .detail-wrap .detail-section .info > h6 {
        font-size: 16px;
        color: #9F9F9F;
        margin-bottom: 0; }
      .detail-wrap .detail-section .info .price {
        width: 100%;
        float: left; }
        .detail-wrap .detail-section .info .price > label {
          color: #9F9F9F;
          font-size: 14px;
          margin-bottom: 0; }
        .detail-wrap .detail-section .info .price > p {
          color: #DA212E;
          font-size: 32px;
          font-weight: 600;
          margin-bottom: 0; }
      .detail-wrap .detail-section .info .row {
        float: left;
        width: 100%; }
      .detail-wrap .detail-section .info .attribute {
        display: flex;
        align-items: center; }
        .detail-wrap .detail-section .info .attribute .isvg {
          width: 32px;
          float: left;
          margin-right: 10px; }
          .detail-wrap .detail-section .info .attribute .isvg .cls-1, .detail-wrap .detail-section .info .attribute .isvg .cls-3 {
            fill: unset; }
          .detail-wrap .detail-section .info .attribute .isvg svg {
            fill: #9F9F9F; }
        .detail-wrap .detail-section .info .attribute .guage .cls-1, .detail-wrap .detail-section .info .attribute .guage .cls-3 {
          fill: none !important; }
        .detail-wrap .detail-section .info .attribute > div {
          float: left; }
          .detail-wrap .detail-section .info .attribute > div > label {
            color: #9F9F9F;
            font-size: 14px;
            margin-bottom: 0; }
          .detail-wrap .detail-section .info .attribute > div > p {
            font-size: 22px;
            font-weight: 600;
            color: #333333;
            margin-bottom: 0; }
      .detail-wrap .detail-section .info .spacer {
        margin: 12px 0;
        width: 100%;
        float: left;
        background-color: #9F9F9F;
        height: 1px; }
    .detail-wrap .detail-section .extra-features {
      padding-top: 56px; }
      .detail-wrap .detail-section .extra-features > h3 {
        color: #333333;
        font-size: 22px;
        font-weight: 600; }
      .detail-wrap .detail-section .extra-features > div {
        -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
        -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
        box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
        width: 100%;
        float: left;
        padding: 28px; }
        .detail-wrap .detail-section .extra-features > div .attr {
          margin-bottom: 26px; }
          @media only screen and (max-width: 768px) {
            .detail-wrap .detail-section .extra-features > div .attr {
              font-size: 14px; } }
          .detail-wrap .detail-section .extra-features > div .attr .check {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            background-color: #E8E8E8;
            display: flex;
            align-items: center;
            justify-content: center;
            float: left;
            margin-right: 12px; }
            .detail-wrap .detail-section .extra-features > div .attr .check::after {
              content: " ";
              display: block;
              width: 8px;
              height: 14px;
              border-right: 3px solid #DA212E;
              border-bottom: 3px solid #DA212E;
              transform: rotate(45deg); }
    .detail-wrap .detail-section .latest-cars {
      padding-top: 56px; }
      .detail-wrap .detail-section .latest-cars > h3 {
        color: #333333;
        font-size: 22px;
        font-weight: 600; }

.about-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .about-wrap .page-wrap {
    float: left;
    width: 100%;
    padding-top: 50px; }
    .about-wrap .page-wrap .services-section {
      background-color: #F5F5F5; }
      .about-wrap .page-wrap .services-section .col-6 {
        border-left: 1px solid #D1D1D1; }
        .about-wrap .page-wrap .services-section .col-6:nth-of-type(2) {
          border-left: 0; }
      .about-wrap .page-wrap .services-section article {
        width: 100%;
        float: left;
        text-align: center; }
        @media only screen and (max-width: 768px) {
          .about-wrap .page-wrap .services-section article {
            margin-bottom: 30px; } }
        .about-wrap .page-wrap .services-section article > img {
          height: 52px;
          margin-bottom: 32px; }
        .about-wrap .page-wrap .services-section article h3 {
          font-size: 22px;
          color: #2F3234;
          font-weight: 600; }
        .about-wrap .page-wrap .services-section article p {
          color: #9F9F9F;
          font-size: 16px; }
        .about-wrap .page-wrap .services-section article .button {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.16);
          background-color: #E8E8E8;
          float: none;
          margin: 0 auto; }
          .about-wrap .page-wrap .services-section article .button:hover {
            background-color: #DA212E; }
          .about-wrap .page-wrap .services-section article .button::after {
            content: ' ';
            border-top: 0;
            border-right: 0;
            border-bottom: 2px solid #FFFFFF;
            border-left: 2px solid #FFFFFF;
            padding: 4px;
            transform: rotate(-135deg);
            width: 10px;
            height: 10px;
            display: block;
            margin-left: -2px; }
    .about-wrap .page-wrap .our-adventages-section .list h3 {
      font-size: 36px;
      font-weight: 600;
      color: #333333; }
    .about-wrap .page-wrap .our-adventages-section .list ul {
      padding: 0;
      margin: 0;
      list-style: none; }
      .about-wrap .page-wrap .our-adventages-section .list ul > li {
        padding: 18px;
        border: 1px solid #E8E8E8;
        margin-bottom: 10px;
        float: left;
        position: relative;
        cursor: pointer;
        width: 100%; }
        .about-wrap .page-wrap .our-adventages-section .list ul > li::after {
          content: " ";
          position: absolute;
          top: 16px;
          right: 16px;
          width: 10px;
          height: 10px;
          border-right: 3px solid #333333;
          border-bottom: 3px solid #333333;
          transform: rotate(-135deg); }
        .about-wrap .page-wrap .our-adventages-section .list ul > li:last-child {
          margin-bottom: 0; }
        .about-wrap .page-wrap .our-adventages-section .list ul > li .isvg {
          float: left; }
          .about-wrap .page-wrap .our-adventages-section .list ul > li .isvg svg {
            width: 20px; }
        .about-wrap .page-wrap .our-adventages-section .list ul > li h6 {
          color: #333333;
          font-size: 22px;
          font-weight: 600;
          padding-left: 16px;
          float: left; }
        .about-wrap .page-wrap .our-adventages-section .list ul > li p {
          font-size: 16px;
          color: #9F9F9F;
          float: left;
          width: 100%;
          margin-bottom: 0;
          margin-top: 20px;
          display: none; }
        .about-wrap .page-wrap .our-adventages-section .list ul > li .cls-1 {
          fill: #DA212E !important; }
      .about-wrap .page-wrap .our-adventages-section .list ul .active::after {
        content: " ";
        position: absolute;
        top: 16px;
        right: 16px;
        width: 10px;
        height: 10px;
        border-right: 3px solid #333333;
        border-bottom: 3px solid #333333;
        transform: rotate(45deg); }
      .about-wrap .page-wrap .our-adventages-section .list ul .active p {
        display: block; }
    .about-wrap .page-wrap .our-adventages-section .image > img {
      width: 100%;
      object-fit: cover;
      height: 100%; }
    .about-wrap .page-wrap .solutions-section {
      position: relative;
      padding: 100px 0; }
      @media only screen and (max-width: 768px) {
        .about-wrap .page-wrap .solutions-section {
          padding: 20px 0; } }
      .about-wrap .page-wrap .solutions-section .overlay {
        width: 100%;
        height: 460px;
        background-color: #2F3234;
        position: absolute;
        top: 0;
        left: 0; }
        .about-wrap .page-wrap .solutions-section .overlay::after {
          content: ' ';
          width: 100%;
          height: 460px;
          display: block;
          background-color: rgba(34, 34, 34, 0.7);
          position: absolute;
          top: 0;
          left: 0; }
        .about-wrap .page-wrap .solutions-section .overlay .overlay-image {
          width: 100%;
          height: 460px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover; }
      .about-wrap .page-wrap .solutions-section article {
        background-color: rgba(218, 33, 46, 0.8);
        float: left;
        width: 100%;
        padding: 40px 20px; }
        @media only screen and (max-width: 768px) {
          .about-wrap .page-wrap .solutions-section article {
            margin-bottom: 15px;
            padding: 20px 20px; } }
        .about-wrap .page-wrap .solutions-section article > div {
          width: 80%;
          float: left;
          padding-left: 52px; }
          .about-wrap .page-wrap .solutions-section article > div > h3 {
            font-size: 32px;
            font-weight: 800;
            margin-bottom: 12px;
            color: #FFFFFF; }
            @media only screen and (max-width: 768px) {
              .about-wrap .page-wrap .solutions-section article > div > h3 {
                font-size: 22px; } }
          .about-wrap .page-wrap .solutions-section article > div > p {
            font-size: 18px;
            color: #FFFFFF;
            float: left;
            margin-bottom: 0; }
            @media only screen and (max-width: 768px) {
              .about-wrap .page-wrap .solutions-section article > div > p {
                font-size: 14px; } }
        .about-wrap .page-wrap .solutions-section article > img {
          width: 20%;
          float: left; }

.contact-wrap {
  float: left;
  width: 100%;
  background-color: #FFFFFF;
  position: absolute;
  overflow-x: hidden; }
  .contact-wrap .page-wrap {
    float: left;
    width: 100%;
    padding-top: 50px; }
    .contact-wrap .page-wrap .box {
      width: 100%;
      background-color: #2F3234;
      color: #FFFFFF;
      padding: 40px;
      float: left; }
      .contact-wrap .page-wrap .box > h2 {
        font-size: 18px;
        font-weight: 600;
        color: #FFFFFF;
        float: left;
        width: 100%;
        margin-bottom: 16px; }
      .contact-wrap .page-wrap .box > p {
        font-size: 14px;
        color: #FFFFFF;
        float: left;
        width: 100%; }
      .contact-wrap .page-wrap .box > h6 {
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
        float: left;
        width: 100%; }
      .contact-wrap .page-wrap .box ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        float: left; }
        .contact-wrap .page-wrap .box ul > li {
          width: 100%;
          color: #FFFFFF;
          display: flex;
          align-items: center;
          margin-bottom: 20px; }
          .contact-wrap .page-wrap .box ul > li > i {
            color: #DA212E;
            margin-right: 20px;
            font-size: 24px;
            float: left; }
          .contact-wrap .page-wrap .box ul > li > span {
            float: left;
            width: 70%; }
    .contact-wrap .page-wrap .map-section {
      position: relative;
      padding: 86px 0 40px 0; }
      .contact-wrap .page-wrap .map-section .container-fluid {
        padding: 0 !important; }
      .contact-wrap .page-wrap .map-section .map {
        width: 100%;
        height: 500px; }
        @media only screen and (max-width: 768px) {
          .contact-wrap .page-wrap .map-section .map {
            height: 200px; } }
